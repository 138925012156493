import { faGithub, faInstagram, faLinkedin, faMedium, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './css/notablog.css';
import './css/theme.css';

const About = () => {
    return (
        <div className="Page">
            {/* Main Content */}
            <header class="Header">

                <div class="Header__Cover">
                </div>

                <div class="Header__Spacer ">
                </div>
                <img src={process.env.PUBLIC_URL + '/images/my_photo_2.jpg'} alt="A description" />

                <h1 class="Header__Title">About me</h1>

            </header>
            <article class="ArticleList">
                <div className="social-icons">
                    <a href="https://www.linkedin.com/in/chi-kang-pai-5231151a3/" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                    <a href="https://twitter.com/bRandom_1105" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faTwitter} />
                    </a>
                    <a href="https://medium.com/@brandonpiii" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faMedium} />
                    </a>
                    <a href="https://www.instagram.com/brandonpiii/" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a href="https://github.com/chikangpai" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faGithub} />
                    </a>
                </div>

                <article class="Article">
                    <h3 class="Article__Title">
                        <a href="https://chikangpai.github.io/" >Click here for professional website</a>
                    </h3>
                    <h3 class="Article__Desc">
                        Enjoy movies, reading, jazz, writing, travel.
                    </h3>
                    <h3 class="Article__Desc">
                        Interested in science and startups.
                    </h3>
                    <h3 class="Article__Desc">
                        Doing science in Harvard Cheimcal Physics.
                    </h3>
                </article>
            </article>
        </div>
    );
}

export default About;
