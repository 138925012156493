import React, { useState } from 'react';
import './css/notablog.css';
import './css/theme.css';
import '../App.css';

const SideBar = () => {
    const [visible, setVisible] = useState(false);

    const toggleSideBar = () => {
        setVisible(!visible);
    };

    const menuItems = [
        { icon: 'bx-home', text: 'Home', link: '/' },
        { icon: 'bx-user', text: 'About me', link: '/About' },
        { icon: 'bx-pencil', text: 'Blog', link: '/Blogs' },
        { icon: 'bx-book', text: 'Read', link: '/Read' },
        { icon: 'bx-microphone', text: 'Podcast', link: '/Podcast' },
        { icon: 'bx-headphone', text: 'Music', link: '/Music' },
        { icon: 'bx-tv', text: 'Watch', link: '/Watch' },
        { icon: 'bx-map', text: 'Travel', link: '/Travel' }
    ];

    return (
        <>
            <div className="SideBar-toggle" onClick={toggleSideBar}>
                ☰
            </div>
            <nav className={`SideBar ${visible ? '' : 'visible'}`}>
                <header className="Header">
                    <div className="Header__Cover"></div>
                    <div className="Header__Spacer"></div>
                </header>
                {menuItems.map((item, index) => (
                    <React.Fragment key={index}>
                        <a href={item.link}>
                            <div className="SideBar__Btn">
                                <i className={`bx ${item.icon} icon-box`}></i>
                                <p>{item.text}</p>
                            </div>
                        </a>
                        {index < menuItems.length - 1 && <span className="SideBar__Delim">·</span>}
                    </React.Fragment>
                ))}
            </nav>
        </>
    );
}

export default SideBar;
